import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { companiesModel } from '../models/companies.model';
import { environment} from '../../../../../environments/environment';
import { copmanyTypeListModel} from '../models/companyTypeList.model';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular/lib/angularFrameworkComponentWrapper';
import { ManageActivitiesModel } from '../../manage-activities/models/manage-activities.model';
import { DataListOptionsModel } from '../models/data-list-options.model';
import { CellPropertyModel } from '../models/cell-property.model';
import { customAttributesModel } from '../models/customAttributes.model';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  public onHeaderSelect: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  public recheckValidation: Subject<void> = new Subject();
  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { } 

  getAllCompanyDeatils(projectId:number){
    return this.http.post<companiesModel[]>(environment.baseUrl + '/transactions/api/company/getAllCompanyDetails',{"projectId": projectId})
  }

  getAllFields(projectId:number){
    return this.http.get<companiesModel[]>(environment.baseUrl + `/transactions/api/customType/getFields?projectId=${projectId}&moduleType=2`)
  }

  getCompanyDetails(companyId, projectId){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+companyId);
  }


  getCompanyTypeList(projectId:number){
    return this.http.post<copmanyTypeListModel[]>(environment.baseUrl + '/company/getAllCompanyType',{
      "projectId": projectId
    })
  }

  //for add new company popup

  getCompany(projectId:number){
    return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId);
  }
  getCompanyForSelfAndTeam(projectId:number,moduleType:Number,teamFlag:boolean=false,projectFlag:boolean=false){
    return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompanyLookup?projectId='+projectId+'&moduleType='+moduleType+'&teamFlag='+teamFlag+'&projectFlag='+projectFlag);
  }
  getCompanyIdName(projectId:number,moduleType:Number,customerView:number=3){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId='+projectId+'&moduleType='+moduleType+'&customerView='+customerView);
  }

  getCompanyFilter(projectId:number, viewMode:number){
      return this.http.post<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/v3/getCompanyLookupSelfOrTeam?projectId='+projectId+'&customerView='+viewMode,null);
  }
  getCompanyFilterWithPageable(projectId:number, viewMode:number,pageNumber:number,pageSize:number,data=null){
    return this.http.post<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/v3/getCompanyLookupSelfOrTeam?projectId='+projectId+'&customerView='+viewMode+'&pageNumber='+pageNumber+'&pageSize='+pageSize,data);
}

  uploadCompany(payload) {
    return this.http.post<copmanyTypeListModel[]>(environment.baseUrl + '/transactions/api/company/upload',payload)
  }

  uploadImage(projectId: number, companyId: number, frontImage: File): Observable<any> {
    const formData: any = new FormData();
    formData.append("projectId", projectId);
    formData.append("companyId", companyId);
    formData.append("frontImage", frontImage);

    console.log(formData);
    return this.http.post(environment.baseUrl + '/transactions/api/company/uploadFrontImage', formData,
    {
      reportProgress: true,
      observe: 'events'

    }).pipe(
      catchError(this.errorMgmt)
    ) 
  }

  bulEditCompany(payload) {
    return this.http.post<copmanyTypeListModel[]>(environment.baseUrl + '/transactions/api/company/upload?isEdit=true',payload)
  }

  
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getAllActivities(projectId: number){
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId);
  }

  getOwnerOptions(projectId: number, moduleId:number){
    return this.http.get(
     environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId='+projectId+'&moduleId='+moduleId);
  }

  getCompanyFilterFromMongo(projectId:number, viewMode:number){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyLookupSelfOrTeam/v2?projectId='+projectId+'&customerView='+viewMode+'&pageNumber='+0+'&pageSize='+10000+'&bufferSize='+48);
  }
  getTotalCountOfCompany(projectId:number, viewMode:number, includeSelf = false){
    return this.http.get(
      environment.baseUrl + '/transactions/api/company/v3/getCompanyLookupBasedOnCustomerViewCount?projectId='+projectId+'&customerView='+viewMode+'&includeSelf='+includeSelf);
}

  getMappedDataListOptions(projectId: number, moduleType: number) {
    return this.http.get<DataListOptionsModel[]>(environment.baseUrl + '/transactions/api/customType/getMappedDataListOptions?projectId='+projectId+'&moduleType='+moduleType);
  }

  public resetPropertyOfDependentCell: Subject<{ rowIndex: number, colKeyCellPropertyMap: Map<string, CellPropertyModel> }> = new Subject();
getFieldsInSetting(moduleType, projectId: number) {
  return this.http.get<any[]>(
    environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType);
}

//Loopkup
getAttribLookup(attributeId: number) {
  return this.http.get<any[]>(
    environment.baseUrl + '/setting/api/customType/getAttribLookupValues?attributeId=' + attributeId);
}

//CustomDateAttribute
getCustomDateAttribute(attributeId: number) {
  return this.http.get<any[]>(
    environment.baseUrl + '/setting/api/customType/getCustomDateAttributes?attributeId=' + attributeId);
}

//CustomDateAttribute
getDataListLookupValues(attributeId: number) {
  return this.http.get<any[]>(
    environment.baseUrl + '/setting/api/customType/getDataListLookupValues?attributeId=' + attributeId);
}

getOwner(projectId: number,moduleId:number){
  return this.http.get<customAttributesModel[]>(
   environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId='+projectId+'&moduleId='+moduleId);
}

getLinkedDataListDetails(moduleType, projectId: number){
  return this.http.get(environment.baseUrl + '/transactions/api/customType/getLinkedDataList?projectId='+projectId+'&moduleType='+moduleType)
}

getActivitySerialNo(projectId, moduleType){
  return this.http.get(
    environment.baseUrl + '/transactions/api/activities/getActivitySerialNo?projectId='+projectId+'&moduleType='+moduleType)
}

add(addData){
  addData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
      return this.http.post(environment.baseUrl + '/transactions/api/company/addEditCompany',addData);
}

getFieldRelation(attributeId,attributeLookupId){
  return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getChildAttributes?attributeId='+attributeId+'&attributeLookupId='+attributeLookupId)
}

getParticularCompany(id,moduleType, projectId: number, callMongoAPI = false){
    if(callMongoAPI){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/mongotransactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
    }
    else{
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
    }
}

getFieldValidation(reqBody:any){
  return this.http.post(
    environment.baseUrl + '/transactions/api/bulkUpload/checkUploadFieldValidation',reqBody
  )
}

  getPrimaryValidation(reqBody:any){
    return this.http.post(
      environment.baseUrl + '/transactions/api/bulkUpload/checkPrimaryAttribute',reqBody
    )
  }

  uploadCompanyINDBank(payload,projectId:any) {
    return this.http.post<copmanyTypeListModel[]>(environment.baseUrl + '/loyalty/api/bulk-upload/company?projectId='+projectId,payload)
  }

}


